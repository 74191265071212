@import './imports.css';

* {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 16px;
}

/* .ant-select { all: unset; position: relative; } */

.ant-select-multiple .ant-select-selection__rendered {
    display: inline-flex !important;
    flex-wrap: wrap !important;
    max-width: 100% !important;
}

.anticon {
    display: flex !important;
    justify-content: center !important;
}

.ant-select-multiple .ant-select-selection__rendered .ant-select-selection__choice {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
    color: black;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-select-single {
    width: 100% !important;
}

:where(.css-zg0ahe).ant-select-single{
    width: 100% !important;
}
:where(.css-zg0ahe).ant-select-multiple{
    width: 100% !important;   
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-select-multiple {
    width: 100% !important;
}

:where(.css-1uweeqc).ant-select {
    width: 100% !important;
}



.ant-select-selection-item-remove {
    width: 30px !important;

}

.ant-select-selector {
    height: 30px !important;
    background-color: var(--c-gray) !important;
}

.ant-select-selector:focus-visible,
.ant-select-selector:focus,
.ant-select-selector:hover {
    outline: none;
    border: 1px solid var(--c-purple);
    border-color: var(--c-purple);
    box-shadow: var(--c-purple);

}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    outline: none;
    border: 1px solid var(--c-purple);
    border-color: var(--c-purple);
    box-shadow: var(--c-purple);
}


:where(.css-dev-only-do-not-override-1g853jt).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--c-gray) !important;
}

.ant-select-item-option-state {
    color: var(--c-white) !important;
}

.ant-select-selector {
    min-height: auto !important;
    height: auto !important;
    max-height: 300px !important;
    overflow-y: auto !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    outline: none;
    border-color: var(--purple-flask);
    box-shadow: var(--purple-flask);
}

.ant-select-focused .ant-select-selector {
    outline: none;
    box-shadow: var(--purple-flask);
    border-color: var(--purple-flask);
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    box-shadow: var(--purple-flask);
    border-color: var(--purple-flask);
}

.ant-select-focused .ant-select-selector {
    box-shadow: none;
    box-shadow: var(--purple-flask);
    border-color: var(--purple-flask);
}

:where(.css-dev-only-do-not-override-1g853jt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    box-shadow: none;
    box-shadow: var(--purple-flask);
    border-color: var(--purple-flask);
}

.input-file-custom[type="file"] {
    display: none;
}

.custom-file-upload {
    width: 100%;
    border: 1px solid var(--c-gray);
    display: inline-block;
    padding: 3px 6px;
    cursor: pointer;
    height: 30px;
    border-radius: var(--radius-input);
    background-color: var(--c-gray);
    margin: 0 auto;
}

.custom-file-upload-disabled {
    background-color: #F5F5F5;
    width: 100%;
    border: 1px solid var(--c-gray);
    display: inline-block;
    padding: 3px 6px;
    cursor: pointer;
    height: 30px;
    border-radius: var(--radius-input);
    /* background-color: var(--c-gray); */
    margin: 0 auto;
}

.nd-main {
    background-color: var(--c-gray);
    height: 100vh;
}

.nd-main,
.nd-content {
    width: 100%;
    overflow: hidden;
}

.nd-main,
.nd-content>.nd-container {
    display: flex;
    flex-direction: column;
}

.nd-content>.nd-container {
    height: 100%;
}

.nd-content {
    height: 100%;

    @media (min-width:991px) {
        padding: var(--padding);
    }

}

.nd-header,
.nd-footer {
    width: 100%;
    flex: 1 0 auto;
    background-color: var(--c-white);
}


.nd-footer {
    max-height: var(--footer);
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--padding);
    direction: rtl;

    @media(min-width:769px) {
        border-radius: 0 0 var(--radius) var(--radius);
    }

}

.nd-header {
    border-bottom: 1px solid #eee;
}

.nd-header>.nd-container {
    display: grid;
    grid-template-rows: 1fr 56px;
    height: 100%;
}

.nd-header-top {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    padding: 10px 20px;  
}


.nd-header-button {
    padding-top: 30px;
    padding: 16px, 0px;
    background-color: var(--c-white);
    color: var(--c-purple);
    font-weight: 500;
    border: none;
}


.nd-header-information {
    font-size: 18px;
    font-weight: 600;
    color: var(--c-black);
    padding-top: 43px;
}

@media(max-width:769px) {
    .nd-header-information {
        padding-top: 99px;
    }
}


.nd-header-bottom {
    border-top: 1px solid #eee;
    display: flex;
    gap: var(--gap);
    align-items: center;
    padding: 0 var(--padding);
    overflow-x: auto;
    flex-wrap: nowrap;

}

.nd-header-bottom::-webkit-scrollbar {
    height: 1px;
    cursor: pointer;
    margin-right: 5px;
}

.nd-header-bottom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--c-white);
    cursor: pointer;
}

.nd-header-bottom::-webkit-scrollbar-thumb {
    background-color: var(--c-white);
    outline: 2px solid var(--c-white);
    cursor: pointer;
    border-radius: 10px;
}

.nd-header-bottom>button {
    white-space: nowrap;

}

.nd-button,
.nd-button-disabled {
    padding: 0px 12px;
    color: var(--c-purple);
    background-color: var(--c-purple-light);
    border: 1px solid var(--c-purple-light);
    height: 44px;
    border-radius: calc(var(--radius)/2);
    white-space: nowrap;
    flex-grow: 1;
}

.nd-button-disabled {
    background-color: var(--c-gray);
}

.nd-button-act {
    padding: 0px 12px;
    border: 1px solid var(--purple-flask);
    background-color: var(--purple-flask);
    color: var(--c-white);
    height: 44px;
    border-radius: calc(var(--radius)/2);
    white-space: nowrap;
    flex-grow: 1;
}


.nd-button-footer-ant {
    border-radius: calc(var(--radius));
    height: 44px;
    border: 1px solid var(--purple-c);
    background-color: var(--purple-c);
    color: var(--c-purple);
    padding: calc(var(--padding)/2);
}

.nd-button-footer-ant-none {}

.nd-button-footer-con {
    border-radius: calc(var(--radius));
    height: 44px;
    border: 1px solid var(--purple-flask);
    background-color: var(--purple-flask);
    color: var(--c-white);
    padding: calc(var(--padding)/2);
}

.nd-body {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow: hidden auto;
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap)*2);
    background-color: var(--c-white);

    @media(min-width:769px) {
        border-radius: var(--radius) var(--radius) 0 0;
    }
}

.nd-body>div {
    z-index: 2;
}

.nd-body-brand {
    height: 81px;
}

.nd-body-item-heading {
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    @media(min-width:769px) {
        border-right: 2px solid var(--c-black);
        text-align: right;
        padding-right: calc(var(--gap)*2);
    }
}

.nd-body-item-label {
    margin-bottom: 0;
    color: var(--c-black);
    font-weight: bold;
}

.nd-body-item {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap)*2);

    @media(min-width:769px) {
        display: grid;
        grid-template-columns: 300px 1fr;
    }

}

.nd-body-item-description {
    /* El parrafo*/

}

.nd-body-item-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: calc(var(--gap)*2);
}

.nd-body-item-content>div {
    height: 66px;
    padding: 2px;
    /* background-color: red;  */
}

.nd-label-error,
.nd-label-info {
    margin: 0 auto;
}


.nd-container-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nd-label-error>span {
    color: var(--c-red);
    font-size: 10px;
}

.nd-label-info>span {
    font-size: 10px;
}


.nd-body-item-content-textarea,
.nd-body-item-content-input {
    padding: 5px;
    width: 100%;
    border: 1px solid var(--c-gray);
    background-color: var(--c-gray);
    border-radius: var(--radius-input);
}

.nd-body-item-content-input {
    height: 30px;
}

.nd-body-item-content-input:focus,
.nd-body-item-content-input:hover,
.nd-body-item-content-input:active,
.nd-body-item-content-input:focus-visible {
    outline: none;
    border: 1px solid var(--c-purple);
    border-color: var(--c-purple);
    box-shadow: var(--c-purple);
}

.ant-select .ant-select-outlined .css-1uweeqc .ant-select-multiple .ant-select-show-arrow .ant-select-show-search {
    width: 100%;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: var(--purple-flask);
}

.nd-body-item-content-select {
    padding: 5px;
    height: 30px !important;
    width: 100%;
    border: 1px solid var(--c-gray);
    background-color: var(--c-gray);
    border-radius: var(--radius-input);
}

.nd-container {
    width: 100%;
    margin: 0 auto;

    @media(min-width: 1600px) {
        max-width: 1600px;
    }
}

.modal {

    /* Ocultar el modal por defecto */
    position: fixed;
    /* Posición fija */
    z-index: 1;
    /* Indice z alto para que esté por encima del contenido */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* Habilitar el desplazamiento si el contenido es demasiado grande */
    background-color: rgba(0, 0, 0, 0.4);
    /* Fondo oscuro semi-transparente */
}


.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}


.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.nd-title-modal {
    display: flex;
    justify-content: center;
    color: var(--purple-flask);
    font-size: 22px;
}

.nd-text-body-modal {
    display: flex;
    justify-content: center;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background-color: var(--purple-flask);
}

input[type="checkbox"]:checked::before {
    content: "\2713";
    display: block;
    text-align: center;
    line-height: 20px;
    color: white;
}

.link-login {
    text-decoration: none;
    margin-top: 13px;
}