.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.containerfields {
  width: 30%;
}

@media (max-width: 960px) {
  .containerfields {
    width: 100%;
    padding: 2%;
  }
}
