#formSalud {
  .nd-main,
  .nd-content {
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden;
  }

  .nd-header > .nd-container {
    display: grid;
    grid-template-rows: 1fr 16px;
    height: 100%;
  }

  .nd-footer {
    margin-top: 10px;
    padding: 12px;
  }

  .form-row label {
    margin-bottom: 0px !important;
    width: 100%;
    color: #128bcc;
    border: 0;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-top: 20px;
  }
}
