:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

.body {
  background: #f2f2f2;
  font-family: Quicksand;
}

.card-signin {
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.no-shadow .card-signin {
  border: 0;
  box-shadow: none;
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-body {
  box-shadow: 0 0 20px gray;
  margin: 2rem auto;
  width: 90%;

  @media (min-width: 500px) {
    max-width: 400px;
  }
}

.card-signin .card-body {
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 1rem !important;
  font-weight: 500 !important;
  border-radius: 10px;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
  background: #9fa1d3;
  color: black;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 0.5rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  /* color: #0360bd; */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.btn-registrarse {
  color: #128bcc;
  background: #f2f2f2;
  border: none;
}

.btn-forgot {
  color: #128bcc;
  background: #f2f2f2;
  box-shadow: none;
  border: none;
}

.color-purple {
  background-color: '#9FA1D3' !important;
}

.ant-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  height: 48px;
  border-radius: 9px;
}

.card-body {
  width: 100%;
  margin-top: 5px;
}

.container-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-fm {
  font-size: 2rem;
  margin: 0 10px;
  text-decoration: none;
  color: inherit;
}

.icon-fm:hover {
  color: #3c3588;
}

.container-input {
  margin-bottom: 10px;
}

.logo-tecnofactor {
  padding: 10px;
  height: 80px;
  width: 80%;
  align-self: center;
}
.logo-container {
  padding: 0px;
  margin: 0px;
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

@media (min-width: 370px) and (max-width: 403px) {
  .logo-tecnofactor {
    padding: 45px;
    height: 139px;
  }
}

.container-logo {
  width: 80%;
  margin: 0 auto;
}

.container-logo img {
  width: 100%;
  object-fit: cover;
}

.botton-login {
  height: auto;
  background-color: #3c3588 !important;
  color: white !important;
}
