.container-user {
  padding-top: 43px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.user-icon {
  font-size: 114px;
  background-color: gray;
  border-radius: 50%;
  height: 96px;
  width: 100px;
  padding: 11px;
  color: white;
}

.font-size-user {
  font-size: 25px;
}

.center-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .container-messages {
    display: none;
  }
}

.container-text-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 46vh;
  padding: 20px;
  font-size: 18px;
  flex: 1;
  text-align: justify;
  padding-right: 43px;
}

.alert-cion {
  width: 400px;
  margin: 10px;
}

.column-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: justify;
  align-items: center;
}

.column-left-home {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  background-color: #e8e3f3;
  margin-top: 10%;
  border-radius: 16px;
}

.container-icon-alert {
  display: flex;
  justify-content: center;
  align-items: center;
}
