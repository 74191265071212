h1 strong {
  color: #026fff;
}

h2 {
  color: #026fff;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 40px;
}

.titulos_label {
  /*color: #0367A6;*/
  color: #128BCC;
  font-weight: 700;
}

.titulo_principal {
  width: 100%;
  color: #128BCC;
  text-align: center;
  font-family: Aclonica;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.input-money {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-money:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.botonera {
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  margin-top: 10px !important;
  width: 100% !important;
  height: 70px !important;
}

@media (max-width: 768px) {
  .botonera {
    align-items: center;
    justify-content: center;
    font-size: 8px !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
    width: 40% !important;
    height: 60px !important;
  }
}

/* styles.css */
.separator2 {
  height: 25px;
  border-right: 2px solid #ccc;
  /* Ajusta el color y tamaño del borde según tus preferencias */
  margin: 0 10px;
  /* Ajusta el margen para el espacio entre los elementos y el separador */
  background-color: #8584841a;
  padding-top: 20px;
  padding-bottom: 40px;
}

.style-movil {
  display: block;
}

@media (min-width: 769px) {
  .style-movil {
    display: flex;
  }
}

@media (max-width: 768px) {
  .space-header {
    margin-top: 40px;
  }
}

.MuiButton-root-143 {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 30% !important;
}

@media (max-width: 768px) {
  .MuiButton-root-143 {
    padding: 0px 0px !important;
  }
}

.style-pedidos {
  margin-top: 5px;
  margin-bottom: 3px;
  border-bottom: dotted 1px;
  align-items: baseline;

}