:root {
    --c-gray: #eee;
    --c-white: #fff;
    --c-purple:#410C60;
    --c-purple-light:#FBF8FF;
    --purple-flask:#3C3588;
    --purple-c:#F2E6F9;
    --c-black:#333;
    --c-red: red;
    --radius: 20px;
    --radius-input:4px;
    --box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    --header: 100px;
    --footer: 70px;
    --padding: 20px;
    --gap:10px;
}