/* reglas
selectores
declaraciones
propiedades
valores */


/* seletor de etiqueta  header - section - article - nav */
/* header {
  background: pink;
  color: red
} */

/* selector descendente  */
/* body header div nav ol li a  */

/* selector de clase . */

/* .link */

/* selector de id # */
/* #portafolio */



/* gris oscuro = #1d252c */
/* gris claro = #626262 */
/* background gris = #1b2127 */
/* celeste = #026fff */

  

  .icon-menu:before {
    content: "\e900";
  }
  
  
  body {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
  }
  
  h1, 
  h2,
  h3,
  h4,
  h5,
  h6{
    font-family: 'Fjalla One', sans-serif;
    font-weight: normal;
    /* font-style: italic; */
  }
  
  h1 {
    font-size: 40px;
    line-height: 1.5;
    letter-spacing: -.2px;
    color: white;
    margin: 0;
    /* margin: 100px 150px 70px 30px; */
    /* text-transform: uppercase; */
    /* text-decoration: dashed; */
  }
  h1 strong {
    color: #026fff;
  }
  h2 {
    color: #026fff;
    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 40px;
  }
  
  .hero {
    height: 300px;
    /* background-image: url('../images/hero.jpg'); */
    background-color: #1b2127;
    /* background-size: 500px 300px; */
    /* background-repeat: no-repeat; */
    /* background-position-x: right; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .hero-image{
    object-fit: cover;
  }
  
  
  .portfolio {
    background: #fafafa;
    padding: 20px;
    /* background: red; */
  }
  
  .project {
    /* border-top: 10px solid red;
    border-right: 10px solid green;
    border-left: 10px solid orange;
    border-bottom: 10px solid pink; */
    /* color: blue; */
    /* border-top-color: red;
    border-top-width: 15px;
    border-top-style: solid; */
    border: 1px solid gray;
    border-radius: 10px;
    /* border-top-left-radius: 50px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
    /* margin: 10px; */
    margin-bottom: 15px;
    /* margin-right
    margin-left 
    margin-top */
    padding: 20px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .project-course,
  .project-date,
  .project-url {
    margin: 10px 0;
  }
  .project-description {
    font-size: 20px;
  }
  .project strong {
    font-family: 'Fjalla One', sans-serif;
  }
  /* .project-date {
    margin: 10px 0;
  } */
  
  .project-title {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  
  .project-details {
    width: 50%;
  }
  
  .project-imageContainer {
    width: 50%;
  }
  
  .project-imageContainer img {
    max-width: 100%;
  }
  
  .header {
    background-color: #1b2127;
    color: white;
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  figure {
    margin: 0;
  }
  
  .header a {
    color: white;
    text-decoration: none;
  }
  .menu {
    height: inherit;
  }
  .header ol {
    display: flex;
    height: inherit;
  }
  .header ol li {
    height: inherit;
  }
  .header a {
    display: flex;
    align-items: center;
    height: inherit;
    padding: 0 10px;
  }
  
  .flexbox {
    display: flex;
    /* flex-wrap: nowrap; */
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  
  .box {
    width: 50px;
    height: 50px;
    margin: 5px;
    border: 1px solid red;
  
    flex-shrink: 0;
  }
  
  .footer {
    color: white;
    background-color: #1b2127;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer img {
    vertical-align: middle;
  }
  
  .footer a {
    color: white;
  }
  
  .event-list {
    display: flex;
    flex-wrap: wrap;
  }
  .event-list-title {
    margin-left: 20px;
  }
  .event { 
    margin: 1%;
    width: 48%;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    background: #f4fbff;
  }
  .event img {
    width: 480px;
    height: 200px;
    object-fit: cover;
  }
  .event-detail {
    margin: 20px 40px;
    margin-top: -40px;
    background-color: white;
    position: relative;
    padding: 20px;
    text-align: center;
  }
  .event-description {
    text-align: left;
  }
  
  .event-url {
    color: #056fff;
    border: 1px solid;
    padding: 5px 20px;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .contact {
    background-color: #056fff;
    color: white;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .social-link {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 10px;
    background-size: 50px 50px;
  }
  
  .container {
    /* border: 1px solid red; */
    max-width: 100%;
    /* width: 100%; */
    flex: 1;
    margin: 0 auto;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    height: inherit;
    flex-wrap: inherit;
  }
  
  .burger-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .8);
    display: none;
    line-height: 40px;
    text-align: center;
    position: fixed;
    z-index: 4;
    left: 5px;
    top: 5px;
    color: white;
  }
  
  /* desktop first que empieces desde el MQ más grande */
  
  @media screen and (max-width: 1023px) {
    .event-list-title {
      margin-left: 0;
    }
    .portfolio {
      padding: 0;
    }
    .container {
      padding: 0 1em;
    }
    .hero {
      height: auto;
      position: relative;
      overflow: hidden;
      padding: 2em;
    }
    .hero-image {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }
    h1 {
      font-size: 2em;
      z-index: 2;
    }
  }
  
  .html-video {
    width: 100%;
    height: auto;
    /* max-width: 100%; */
  }
  
  .youtube-video {
    /* width: 100%; */
    /* height: auto; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .flexible-video {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background: blue;
    position: relative;
  }
  
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 1.2em;
      margin: .5em 0;
    }
    .burger-button {
      display: block;
    }
    .header {
      display: block;
      height: auto;
    }
    .header ol {
      display: block;
    }
    .header ol li {
      height: 50px;
    }
    .header a {
      justify-content: center;
      height: 40px;
      font-size: 2em;
      text-decoration: underline;
      margin-bottom: .5em;
    }
    .header .logo {
      text-align: center;
    }
  
    .menu {
      position: fixed;
      background: rgba(5, 111, 255, .9);
      z-index: 3;
      top: 0;
      left: -100vw;
      width: 100vw;
      /* height: 100vh; */
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
    }
    .menu.is-active {
      left: 0;
    }
  
    .project {
      display: block;
    }
    .project-imageContainer {
      width: auto;
      text-align: center;
    }
    .project-details {
      width: auto;
      /* font-size: 10px; */
    }
    .project-course, .project-date, .project-url { 
      margin: .3em 0;
    }
    .project-title {
      font-size: 1.5em;
      margin-bottom: .3em;
    }
    .project-url {
      font-size: 1em;
  
    }
    .project-course {
      font-size: .7em;
  
    }
    .project-date {
      font-size: 1em;
  
    }
    .project-description {
      font-size: 1em;
  
    }
  
    .event {
      width: auto;
      flex-shrink: initial;
    }
  
    .event img {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    h1 {
      font-size: 1.5em;
    }
    .project-details {
      font-size: 14px;
    }
  
    .event-detail {
      /* 1em = 16px */
      margin: -2em .5em .5em;
      padding: 1em;
    }
  
    .contact {
      height: auto;
      padding: 1.5em 0;
      text-align: center;
      display: block;
    }
    .form-email button {
      margin: 1em auto;
    }
    .form-email h3 {
      margin-top: 0;
    }
    .footer {
      display: block;
      text-align: center;
      border: 1px solid transparent;
    }
    .social-link {
      width: 40px;
      height: 40px;
      background-size: 100%;
    }
  }
  
  
  @media screen and (max-width: 320px) {
    h1 {
      font-size: 1.2em;
      /* background: rgba(0,0,0,.5) */
    }
    .hero {
      padding: 2em 1em;
    }
  }

  .titulo_principal{
    width: 100%;
    color: #128BCC;
    text-align: center;
    font-family: Aclonica;
    margin: 0px;
    padding: 0px;
    border: 0px;
  }

  .titulos_label{
    /*color: #0367A6;*/
    color: #128BCC;
    font-weight: 700;
  }
  
  .spacing-head{
    display: flex;
    margin-top: 0px;
   }
  
   @media screen and (max-width: 720px) {
    .spacing-head {
      
      margin-top: 60px;
    }
   }


  fieldset {
    border: none;
    padding: 10px;
  }

  legend {
    font-weight: bold;
    margin-bottom: 10px;
  }

  /* Estilos específicos de cada paso */
  fieldset.hidden {
    display: none;
  }

  .hidden {
    display: none;
  }

.nav-tabs {
  border-bottom: 4px solid #e8e8e8 !important;
  line-height: 1.2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.nav-tabs:header{
  margin-bottom: 15px !important;
  border-bottom-color: #8acbe9!important;
}

/* Estilos para los elementos de navegación */
.nav-item {
  color: #333;
  padding: 0px;
  cursor: pointer;
}

/* Estilos para los elementos de navegación */
.nav-item :hover{
  padding: 10px;
  color: #ffffff !important;
  cursor: pointer;
  background-color: #128BCC !important;
  font-weight: bold !important; 
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border-bottom: 4px solid #8acbe9 !important;
  font-weight: bold !important; 
}


/* styles.css */
@keyframes handAnimation {
  0% {
    transform: translateX(-10px) rotate(0deg);
  }
  50% {
    transform: translateX(10px) rotate(20deg);
  }
  100% {
    transform: translateX(-10px) rotate(0deg);
  }
}

.hand {
  animation: handAnimation 2s infinite;
  cursor: pointer;
}

/* styles.css */
.separator {
  height: 25px;
  border-right: 2px solid #ccc; /* Ajusta el color y tamaño del borde según tus preferencias */
  margin: 0 10px; /* Ajusta el margen para el espacio entre los elementos y el separador */
}

.text-center{
  text-align: center;
}

.separador-line{
  padding-bottom: 20px;
  border-bottom: 2px dotted rgb(209, 209, 209);
  margin-top: 30px;
}

.line-input{
  margin-top: 10px;
  border-radius: 3px !important;
  padding: 0px !important;
  border: solid 1px #CED4DA !important;
  text-align: center;
}

.button-descuentos{
  background-color: rgb(18, 139, 204);
  padding:2px;
  border-radius:8px;
  cursor: pointer;
  font-weight: 400 !important;
}

/*Estilo de los Botones Enviar y Vista Previa*/
.buttonEnviar{
  background-color: #92C63E !important;
  color: white !important;
  border: none !important;
}











