*,
*::before,
*::after {
    box-sizing: border-box;
    /* Set the sizing of an element to include it's border */
}

* {
    margin: 0;
    /* Set the default margin to 0 */
    padding: 0;
    /* Set the default padding to 0 */
}

ul[role='list'],
ol[role='list'] {
    list-style: none;
    /* Turn off numbered and unordered list decoration */
}

html:focus-within {
    scroll-behavior: smooth;
    /* Make the scrolling inside of any scrollable element smooth */
}

a:not([class]) {
    text-decoration-skip-ink: auto;
    /* Makes link undelines look better */
}

img,
picture,
svg,
video,
canvas {
    max-width: 100%;
    /* Makes it responsive */
    height: auto;
    /* Makes it responsive */
    vertical-align: middle;
    /* Makes text next to inline images look better */
    font-style: italic;
    /* If the images don't load it makes the alt decription look better */
    background-repeat: no-repeat;
    /* The background repeat and size are there if you want to load a picture first like a backroung image that is worse quality while the better quality image loads */
    background-size: cover;
}

input,
button,
textarea,
select {
    font: inherit;
    /* Makes these elements inherit fonts */
}

/* Turns off animation for people who don't want to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
        transition: none;
    }
}

body,
html {
    height: 100%;
    /* Makes the body element full screen */
    scroll-behavior: smooth;
    /* Makes normal scrolling smooth */
}