body {
  padding: 0;
  margin: 0 auto;
  width: 90%;
  background-color: whitesmoke;
}

.App {
  font-family: Arial, Helvetica, sans-serif;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1,
h2,
h3,
p,
li {
  padding: 0;
  margin: 0;
  line-height: 1;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 12px;
}

p {
  font-size: 12px;
}

.info {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

#modalNews {
  [class^='MuiDialog-paperWidthSm'] {
    max-width: 500px !important;
  }
  [class^='ModalSlider-closeBtn'] {
    position: fixed !important;
  }

  .container__slider__links {
    position: fixed !important;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
}
