.contenedor-logo {
    width: 140px;
    height: 140px;
    overflow: hidden;
    background-Color:#EC7404;
    padding: 0;
    border-Radius: 20px;
    margin:auto;

}

.img-logo {
    margin:20px;
}

.tittle-bienvenida{ 
    margin: 20px;
    text-align: center;   
}

.tittle-ciudades {
    text-align: center;
    margin-top: 4%;
  
}

.sede {
   
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    background-color: #EC7404;
    box-shadow: 3px 3px 3px #00000096;
    flex-direction: column;
    align-items: center;
    color:white;



  }
  
  .selectDiv {

    position: relative;
    border: 1px solid #ced4da;
    padding: 3px 4px 0px 3px; 
    height :38px;
    width: 50%;
    margin: auto;
    margin-top: 5%;
    border-radius: 2px;
    text-align: center;

  }

  .select {
    width:100%;
  }